<template>
  <v-flex shrink :class="['icon-style']" v-bind="$attrs" v-on="$listeners"
    ><slot></slot
  ></v-flex>
</template>

<script>
export default {
  props: {
    icon: "",
    class: ""
  },
};
</script>

<style scoped lang="scss">
.icon-style {
  font-family: "b-icons";
  font-size: 36px;
  font-weight: 400;
  cursor: pointer;
}
</style>